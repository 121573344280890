import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Page from "../components/page"
import Wrap from "../components/wrap"

const PageThanks = ({location}) => {
  const userId = location.state ? location.state.userId : "0";
  return (
    <>
      <Page title="無料体験レッスン受付完了">
        <Wrap>
          <script src={`https://r.moshimo.com/af/r/result.js?p_id=3168&pc_id=7401&m_v=${userId}`} id="msmaf"></script>
          {/* <noscript><img src={`https://r.moshimo.com/af/r/result?p_id=3168&pc_id=7401&m_v=${userId}`} width="1" height="1" alt="" /></noscript> */}
          <img src="https://tr.smaad.net/tag?id=514862686&campaign_id=104648335" width="1" height="1" />
          <h2>お問い合わせありがとうございます</h2>

          <p>
            この度は無料体験レッスンにお申し込みいただきありがとうございます。
            <br />
            できる限り迅速に弊社スタッフよりご連絡させていただきますので、今しばらくお待ちくださいませ
          </p>

          <p>
            急ぎのご用件・弊社から連絡が無かった場合はお手数ですが下記連絡先にご連絡いただけますと幸いです。
          </p>

          <ContactInfo>
            電話：06-6226-8667（受付時間 12:00-22:00）
            <br />
            メール：<a href="mailto:info@tryhard.me">info@tryhard.me</a>
          </ContactInfo>

          <p>
            ※弊社からお送りするメールが迷惑メール扱いになり、無料体験予約やお問合せなどのご連絡が届かないケースがあります。
            迷惑メール扱いや、受信拒否にならないよう、メール振り分け設定、迷惑メールフィルター等のご確認や、連絡先アドレス帳等へのご登録をお願いいたします。
            <br />
            弊社のメールアドレスは{" "}
            <a href="mailto:info@tryhard.me">info@tryhard.me</a> です。
          </p>

          <p style={{ textAlign: "center" }}>
            <Return to="/">TOPに戻る</Return>
          </p>
        </Wrap>
      </Page>
    </>
  )
}

export default PageThanks

const Return = styled(Link)`
  display: inline-block;
  padding: 0.5em 2em;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.red};
  color: white;
  text-decoration: none;
  transition: background 0.25s;

  &:hover {
    background: black;
  }
`

const ContactInfo = styled.p`
  background: #f1f1f1;
  padding: 0.75em 1em;
  font-size: 1.1rem;
`
